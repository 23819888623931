@import "../../styles/variables";

.resources-container {
  .header {
    display: flex;
    justify-content: space-between;

    .add-button {
      background-color: $primary;
      color: $white;
    }
  }

  .generic-table-container {
    background-color: $white-bis;
    padding: 20px 10px 0 10px;
  }
}

.dashboard-resources-container {
  h1.title {
    background-color: $white-bis;
    text-align: center;
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
    text-transform: uppercase;
    padding-top: 20px;
  }
  .header {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .generic-table-container {
    background-color: $white-bis;
    padding: 20px 10px 20px 10px;
  }
}
