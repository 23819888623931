@import "../../styles/variables";

@keyframes moveInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.details-container {
  width: 60%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding: 150px 50px 50px 50px;

  .title {
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
  }

  .section {
    border-bottom: 1px solid $primary;
  }
}

.export-container {
  align-items: center;
  line-height: 1;
  .print-button {
    background-color: transparent;
    color: #fb7753;
    border-color: unset;
    border-width: 0;
    padding-top: 0;
    position: relative;
  }
}

.details-container-modal {
  @media print {
    overflow: visible !important;
  }
  animation-name: moveInFromLeft;
  animation-duration: 1s;
  width: 100%;
  height: 30rem;
  position: relative;
  overflow-y: auto;
  .details-modal-header2{
      flex-direction: "row";
      display: "flex" ;
      align-items: "center";
      @media only screen and (max-width: 700px) {
        flex-direction: "column";
        margin-bottom: 1rem;
      }
  }
  .title {
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
  }

  .section {
    border-bottom: 1px solid $primary;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details {
  background-color: #f5f5f5;
  padding: 2rem;
}

.details-modal {
  background-color: #f5f5f5;
  padding: 0.5rem 1rem 1rem 1rem;
}

.eval-details-container {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 700px) {
  .details-container {
    width: 100%;
  }
}
