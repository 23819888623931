 .datePicker{
    width: 100%;
    height: 36px;
    padding: 0.5em;
    width: 200px;
    border-color: #C4C4C4;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    align-items: center;
    letter-spacing: 0.01071em;
    font-family: Roboto Helvetica Arial sans-serif;
    font-size: 0.9rem;
    overflow: hidden;
 }
 .textInputRow {
    width: 100%;
     margin-bottom: 0.25em;
     font-size: 0.9rem;
 }

 .popper {
     z-index: 10000;
     position: relative !important;
     top: 0px !important;
     transform: none !important;
 }
 
.react-datepicker__input-container{
  width: 200px;
}
 ;