@import "../../styles/variables";

.project-card-container {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  padding: 40px 0px 40px 0;
  z-index: 1;
  background-color: $white;

  .generic-table-wrapper {
    width: 95% !important;
    margin: auto;
  }

  .bg-mask {
    display: none;
    position: absolute;
    width: calc(100% - 140px);
    height: 610px;
    background-color: $white-bis;
    top: 125px;
    margin-top: 110px;
  }

  .project-card-wrapper {
    position: relative;
    margin-top: 150px;
    height: calc(100vh - 265px);

    margin-left: auto;
    margin-right: auto;

    .export-container {
      position: absolute;
      margin: 0;
      padding: 0;
      height: 10px;
      width: 10px;
      max-width: 10px;
      font-size: 0.5rem;
      left: -70px;
      top: 30px;
      .fa-download {
        font-size: 1rem;
      }
    }

    .print-button {
      background-color: transparent;
      color: $secondary;
      border-color: unset;
      margin-top: 2px;
      border-width: 0;
      padding-top: 0;
    }

    .large-screen-title {
      display: none;
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      top: 260px;
      position: absolute;
      gap: 20px;
      z-index: 1;

      .button:not(:last-child):not(.is-fullwidth) {
        margin-right: 0;
      }

      button {
        min-width: 20px;
        text-transform: uppercase;
        padding: 8px;
        border-radius: 6px !important;
        background-color: $white;
        color: $primary;
        border: 4px solid $primary;
        font-family: "Oxygen", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        justify-content: center;
        margin: 0;
      }

      button.cancel-button {
        background-color: $danger;
        color: white;
        border-color: $danger;
      }
    }

    .top-section {
      background-color: $primary;
      clip-path: unset;
      height: 285px;
      width: 100%;
      margin-left: unset;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -221px;
      z-index: -1;
      &:after {
        content: "";
        position: absolute;
        height: 35px;
        width: 100%;
        top: 250px;
        background: repeating-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.4) 15px,
          $primary 15px,
          $primary 40px
        );
      }

      label {
        color: white;
      }

      &.edit-mode {
        &:after {
          top: 265px;
          height: 20px;
        }
      }

      .project-name {
        color: white;
        margin-bottom: 0;
        margin-top: -30px;
        span {
          font-family: "EurostileExtended", sans-serif;
          font-size: 1.25rem;
          text-transform: uppercase;
        }

        .c-secondary {
          color: $secondary;
        }
      }

      .project-info {
        color: white;
        font-family: "Oxygen", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .project-info-item {
          display: flex;
          & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 20px;
            &:first-child {
              align-items: flex-start;
            }
            & > span {
              font-family: "Oxygen", sans-serif;
            }
          }
        }
      }
    }

    .center-section {
      .tabs > ul {
        flex-direction: column;
        margin-bottom: 10px;
      }

      display: flex;

      top: 350px;
      height: 475px;

      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .center-table {
        width: 95%;
        height: 100%;
        margin-bottom: 20px;

        .center-table-wrapper {
          overflow-y: auto;
          height: 100%;
          background: $white-bis;
          padding-top: 10px;
          padding-bottom: 10px;

          h3 {
            text-align: center;
            text-transform: uppercase;
            font-family: "EurostileExtended", sans-serif;
            color: $primary;
            font-size: 1rem;
            margin-bottom: 25px;
          }

          .tabs ul {
            max-width: 75%;
            color: $primary;
            font-family: "Oxygen", sans-serif;
            font-size: 0.875rem;
            text-transform: uppercase;
            font-weight: 600;
            margin: auto;
          }

          .yos-note-input {
            width: 90%;
            margin: auto;
            padding-bottom: 10px;
          }

          .table-info {
            width: 100%;
            max-width: 100vw;
            padding-left: 1rem;
            padding-right: 1rem;

            & > div {
              display: flex;
              flex-direction: column;
              &:first-child {
                padding-right: 20px;
              }
              & > div {
                color: $primary;
                font-family: "Oxygen", sans-serif;
                font-weight: 600;
              }
            }

            .ti-01 {
              flex-direction: row;
              min-width: 10%;
              width: 33%;
            }

            input {
              &:not(:last-child) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .right-table {
        width: 95%;
        height: 100%;
        background-color: $secondary;
        margin-bottom: 20px;

        .right-table-wrapper {
          padding: 10px 25px 25px 25px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .header-wrapper {
            display: flex;

            .print-button {
              margin-top: 7px;
              color: $primary;
            }
          }

          h3 {
            text-align: center;
            text-transform: uppercase;
            font-family: "EurostileExtended", sans-serif;
            color: $white;
            font-size: 1rem;
            margin-bottom: 1rem;
          }

          .table-info {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding-bottom: 10px;
            color: white;

            & > div {
              display: flex;
              flex-direction: column;
              gap: 5px;
              &:first-child {
                align-items: flex-start;

                max-height: 365px;
                overflow-y: auto;
              }
              & > span {
                color: $white;
                font-family: "Oxygen", sans-serif;
                font-weight: 600;
              }
            }

            .print-button {
              color: $primary;
            }

            .cell-wrapper {
              div {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                color: $white;
                width: 100%;
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
              }

              .tech-courses-input-wrapper {
                display: flex;
                .input {
                  flex-grow: 1;
                }

                .control.is-expanded {
                  input {
                    flex: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .project-card-container {
    .project-card-wrapper {
      .top-section {
        display: flex;
        justify-content: center;

        top: -70px;

        .project-info {
          margin-top: 0px !important;
        }

        .export-container {
          left: 120px;
          top: 19px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .project-card-container {
    .project-card-wrapper {
      position: static;
      .buttons {
        margin-top: 1rem;
      }
    }
  }
}

@media only screen and (min-width: 667px) {
  .project-card-container {
    .project-card-wrapper {
      .top-section {
        .tabs > ul {
          flex-direction: row !important;
          margin-bottom: 10px;
        }

        .print-button {
          margin-top: 7px;
        }

        .project-info {
          padding: 0;

          .project-name {
            span {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1340px) {
  .project-card-container {
    padding: 60px 80px 80px 60px;
    background-color: transparent;

    .large-screen-title {
      display: block !important;
      top: 35px;
      margin-top: 200px;
      position: absolute;
    }

    .small-screen-title {
      display: none !important;
    }

    .c-secondary {
      color: $secondary;
    }

    .project-name {
      margin-left: 50px;
      margin-top: 5px;
      margin-bottom: 0;

      span {
        font-family: "EurostileExtended", sans-serif;
        font-size: 1.75rem !important;

        text-transform: uppercase;
      }
    }

    .export-container {
      position: absolute;
      left: -120px !important;
      top: 21px !important;
    }

    .bg-mask {
      display: block;
    }

    .project-card-wrapper {
      margin-top: 110px;
      max-width: unset;
      margin-left: unset;
      margin-right: unset;

      .print-button {
        top: 0;
        left: 0;
      }

      .buttons {
        top: 525px;
        width: 300px;
        flex-direction: column;
        gap: 10px;

        button {
          min-width: 200px;
          width: 200px;
          padding: 20px;
          background-color: $primary;
          color: white;
          border: unset;
          font-size: 0.875rem;
        }

        span.file-label {
          width: 200px;
        }
      }

      .top-section {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0px 100%, 0 0);
        height: 160px;
        width: 100%;
        margin: auto !important;

        top: 0;
        z-index: 0;
        position: absolute;
        justify-content: start;

        &:after {
          height: 90px;
          width: 100%;
          top: 120px;
        }

        .project-info {
          position: relative;
          top: 0px !important;
          margin: 0;

          flex-direction: row;

          .project-info-item {
            justify-content: flex-start;
          }
          top: 20px;

          width: calc(100%);

          .project-info-item {
            & > div {
              width: auto;
              &:first-child {
                align-items: flex-end;
              }
              &:nth-child(2) {
                align-items: flex-start;
              }
              & > span {
                font-size: 1rem;
              }
            }
          }
        }
      }

      .center-section {
        flex-direction: row;
        width: calc(80%);
        left: 20%;
        top: 150px;
        margin-top: 30px;

        .center-table {
          margin-top: 25px;
          .center-table-wrapper {
            h3 {
              font-size: 1.5rem;
            }

            .table-info {
              .cell-wrapper {
                div {
                  flex-direction: row;
                  textarea {
                    min-width: 930px;
                  }
                  .control.is-expanded {
                    input {
                      max-width: 160px;
                      padding-top: 12px;
                    }
                  }
                }
              }
            }
          }
        }

        .right-table {
          margin-bottom: 0;
          margin-right: 20px;
          width: 60%;
          font-weight: 600;

          .right-table-wrapper {
            h3 {
              font-size: 1.5rem;
              border-bottom: rgba(255, 255, 255, 0.4) 1px solid;
            }
          }
        }
      }
    }
  }
}
