@import "../../styles/variables";

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: 28px;
    font-weight: 550;
  }
}

.modal-details {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: $grey;
  margin-bottom: 1rem;
}
