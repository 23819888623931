@import "../../../styles/variables";

.data-table-container {
  position: relative;
  max-height: 73vh;
  overflow: auto;

  .partner-table-container {
    .t-container {
      width: 100%; //table size
      margin: auto; //center table

      .searchbar-container {
        width: 200px;
        margin-left: auto;
      }
    }

    &.is-fixed-width {
      max-width: 100%;
    }

    table {
      width: 90%;
      margin: auto;
      border-collapse: separate;
      border-spacing: 0 1.5rem;
      background-color: transparent;
      td {
        font-family: $font-secondary !important;
        color: $primary;
        border: none !important;
        flex: 3; // make profile image take up less room

        // .cell-img-wrapper {
        // }
        svg {
          font-weight: normal !important;
        }
      }

      td:nth-child(1) {
        text-align: center; // keep profile button closer to rows
        flex: 1;
        width: 62px;
      }
      td,
      th {
        cursor: pointer;
      }
      thead {
        tr {
          th {
            white-space: nowrap;
            font-weight: normal;
            letter-spacing: 1px;
            font-size: 1rem;
            flex: 3;
            background-color: $white;
            color: $primary;
            padding-left: 1rem;
            border-bottom: 1px solid $primary;
            font-weight: 600;

            &:first-child {
              border-bottom: unset;
            }

            &:first-child {
              background-color: $white;
            }
            // the down arrow table header. Removed space for better alignment
            &:nth-child(2) {
              width: 1px;
            }
          }
        }
      }

      tbody {
        tr {
          background-color: transparent;
          &:hover {
            td {
              .cell-style-wrapper {
                background-color: $white-ter;
              }
            }
          }

          td {
            vertical-align: middle;
            padding: 0;

            .cell-style-wrapper {
              background-color: $grey-light;
            }

            &:first-child {
              background-color: $white;
            }

            .user-icon-wrapper {
              width: 62px;
              // padding: 15px;
              margin-right: 35px;
              border-radius: 50px;
              border: 1px solid $secondary;
              background-color: $secondary;

              svg {
                font-size: 26px;
                color: $white;
              }
            }

            .image-icon-wrapper {
              text-align: right;
              margin-right: 35px;

              img {
                height: 60px;
                width: 60px;
                max-width: 60px;
                max-height: 60px;
                // object-fit: cover;
              }
            }
          }
        }
      }

      tr {
        width: 100%;
        white-space: normal;
        transition: text-shadow 0.2s;
        img {
          border-radius: 50%;
        }
      }
    }
    pre {
      padding: 0;
      background: none;
    }

    .pagination-field {
      padding-bottom: 20px;
    }

    .sub-row {
      .sub-cell {
        height: 100%;
        vertical-align: unset;
        background-color: $white-ter;
        max-width: 30vw;
        position: relative;
        right: 6vw;

        text-align: center;

        &:not(:first-child) {
          border-left: 5px solid $white !important;
        }

        &:not(:last-child) {
          border-right: 5px solid $white !important;
        }

        .generic-table-container {
          max-height: 400px;
          overflow-y: auto;

          .generic-table {
            border-collapse: collapse;

            th {
              border-bottom: 1px solid $primary;
            }

            // first column
            td:first-child {
              min-width: 20ch;
            }
            // other columns
            td:not(:first-child) {
              min-width: 12ch;
            }

            .cell-style-wrapper {
              white-space: normal;

              font-weight: normal;
              color: $primary;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.no-employees-found-wrapper {
  display: flex;
  margin: auto;
  justify-content: center;
  border-top: 1px $primary solid;
  margin-top: 41px;
  width: 75%;
  .notification {
    background-color: $grey-light;
    color: $primary;
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }
}
