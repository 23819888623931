@import "../../styles/variables";

.projects-container {
  height: auto;
  min-height: 100vh;
  position: relative;
  padding: 150px 50px 50px 50px;

  .add-btn {
    background-color: $green;
    color: white;
    height: 40px;
    &:hover {
      background-color: lighten($color: $green, $amount: 5%);
      color: white;
    }

    &:focus {
      color: white;
      border: none;
    }
    &:active {
      color: white;
      transform: translateY(1px);
      filter: saturate(110%);
      border: none;
    }
  }
}
