@import "../../styles/variables";

.generic-table-container {
  .title {
    text-align: center;
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
    text-transform: uppercase;
  }

  .generic-table {
    background-color: transparent;
    width: 100%;
    tbody {
      tr {
        &.is-hoverable {
          cursor: pointer;
        }
        &:last-child {
          td {
            border-bottom-width: 2px;
          }
        }
      }
    }

    th {
      border-width: 0 0 2px 0;
      border-color: $secondary;
    }
    tr {
      th {
        font-family: "Oxygen", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: $primary;
        &:not([align]) {
          text-align: center;
        }
      }

      td {
        text-align: center;
        border: 2px solid $primary;
        color: $font-grey;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 0.75rem;
        vertical-align: middle;

        &:first-child {
          border-width: 2px 2px 2px 0;
          color: $primary;
          font-weight: 600;
        }

        &:last-child {
          border-width: 2px 0 2px 2px;
        }
      }

      &:last-child {
        td {
          border-bottom-width: 2px;
        }
      }
    }
  }
}
