@import "../../styles/variables";

.loader-nav {
  height: 110px !important;
  background-color: $primary;
}

.loader-nav-expanded {
  height: calc(100vh - 110px);
  width: 100vw;
}
