$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: #7a7a7a;
$grey-light: #f7f7f7;
$grey-lighter: #dbdbdb;
$grey-lightest: hsl(0, 0%, 98%);

$white-ter: #f5f5f5;
$white-bis: #f7f7f7;
$white: hsl(0, 0%, 100%);

$orange: hsl(14, 100%, 53%);
$yellow: hsl(45, 94%, 14%);
$green: hsl(141, 56%, 43%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$blue: hsl(217, 71%, 53%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);
$red-dark: hsl(348, 69%, 53%);
$light-blue: #6981a8;

$primary: #052e6f;
$secondary: #fb7753;
$green-med: #fcba63;
$green-med-faded: #e8eaf1;
$background: #e8eaf1;
$font-grey: #666666;
// $tertiary: #e27f0b;

$font-secondary: "Oxygen", sans-serif;
// $text: #333333;

// $warning: #fdd154;
$success: #7eb2c9;
$danger: #fb7753;
// $link: #7eb2c9;
// $info: #e27f0b;
