@import "../../styles/variables";

.container {
  display: inline;
  width: 100%;
}

.inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  position: relative;

  .button-group {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0;

    button {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .year-dropdown {
    margin-left: auto;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
    min-width: 100px;

    &:focus {
      outline: none;
      border-color: #4a90e2;
    }
  }
}

.reviews-outer-container {
  display: flex;
  width: 100%;
}

.reviews-outer-container-manager {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.reviews-container-section:first-child {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  margin-left: 2rem;
  margin-right: 1rem;
}

.reviews-container-section {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.reviews-container-section:last-child {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  margin-left: 1rem;
  margin-right: 2rem;
}

.selectedBtn {
  color: #fff;
  background-color: $secondary;
  margin: -1px;
}

.unselectedBtn {
  color: $primary;
  border-bottom: 2px solid $secondary;
  margin: -1px;
}

.title {
  color: $primary;
  font-family: "EurostileExtended", sans-serif;
}

.centered-title {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.section {
  border-bottom: 1px solid $primary;
}

.reviews {
  background-color: #f5f5f5;
  padding: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}

.review-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.review-item-mobile {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.review-title {
  font-weight: 600;
  margin-right: 0.5rem;
}

.review-option-primary {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $primary;
  color: #fff;
}

.review-option-secondary {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $secondary;
  color: #fff;
}

.review-option-sign-off {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $green-med;
  color: #fff;
}

.backBtn {
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 2rem;
  background-color: $primary;
  color: #fff;
}

.title-container {
  display: flex;
  align-items: center;
}

.title-end {
  margin-left: auto;
}

@media (max-width: 700px) {
  .reviews-outer-container-manager {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .reviews-container-section {
      width: 100%;
      height: auto;
      min-height: unset;
      position: relative;
      padding-top: 2rem;
      margin-left: 0rem;
      margin-right: 0rem;
    }

    .reviews-container-section:first-child {
      width: 100%;
      height: auto;
      min-height: unset;
      position: relative;
      padding-top: 2rem;
      margin-left: 0rem;
      margin-right: 0rem;
    }

    .reviews-container-section:last-child {
      width: 100%;
      height: auto;
      min-height: unset;
      position: relative;
      padding-top: 2rem;
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }
}