@import "../../styles/variables";

.dashboard-card-container {
  background-color: $green-med;
  position: relative;

  min-width: 500px;

  .dashboard-card-wrapper {
    position: relative;
    background-color: $white-ter;
    top: -10px;
    right: 10px;
    padding: 20px 0 10px 0;
  }

  .title {
    text-align: center;
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
    text-transform: uppercase;
  }

  .dashboard-list-wrapper {
    text-align: center;
    padding-bottom: 10px;

    div {
      display: flex;
      flex-direction: column;
      padding: 0 50px;

      &:not(:first-child) {
        padding-top: 5px;
      }

      .link-wrapper {
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid $primary;
        }
      }

      .list-item-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          font-family: "Oxygen", sans-serif;
          font-weight: 600;
          font-size: 1.1rem;

          &:first-child {
            color: $primary;
          }

          &:nth-child(2) {
            color: $secondary;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

// media query for mobile

@media only screen and (max-width: 1366px) {
  .dashboard-card-container {
    width: 100%;
    min-width: 0;

    .dashboard-list-wrapper > div {
      padding: 0;

      .list-item-wrapper {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
