@import "../../styles/variables";

div.app-container > nav {
  position: absolute;
  background-color: $primary;
  height: 90px;
  width: 100vw;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.09);

  @media only screen and (min-width: 1200px) {
    height: 90px;
  }

  .navbar-brand {
    height: 100%;
    image {
      height: 100%;
    }
  }
  .navbar-item {
    color: white;
    font-size: 1.35rem;
    font-weight: bold;

    // first navbar item
    .decco-logo {
      padding: 0 0 0 10px;
    }

    &:hover {
      text-decoration: underline;
    }

    .decco-logo {
      margin-left: 10px;
      height: 95%;
      min-height: 95%;
      width: auto;
    }

    .title {
      color: white;
      text-transform: uppercase;
      margin-left: 20px;
      letter-spacing: 0;
      font-size: 1.5rem;
      font-family: "EurostileExtended", sans-serif;
      display: none;
      position: relative;

      @media only screen and (min-width: 768px) {
        display: block;
      }

      @media only screen and (min-width: 1200px) {
        margin-left: 62px;
        font-size: 2.188rem;
      }
    }
  }
  .navbar-end {
    align-items: center;

    .nav-subtitle {
      height: 100%;
      background-color: $primary;
      color: white;
      font-size: 1rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding-right: 10px;
      padding-left: 10px;
      cursor: pointer;
      &.highlight2 {
        background: lighten($primary, 5%);
      }
      @media only screen and (min-width: 1300px) {
        font-size: 1.35rem;
      }

      &:hover {
        background: lighten($primary, 5%);
      }
    }

    .profile-button {
      width: 51px;
      height: 50px;
      min-height: 50px;
      min-width: 50px;
      border-radius: 50px;
      margin-left: 10px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 5px;
      cursor: pointer;

      img {
        border-radius: 50px;
        width: 51px;
        height: 50px;
        min-width: 51px;
        min-height: 50px;
        object-fit: cover;

        @media only screen and (min-width: 1200px) {
          width: 70px;
          height: 70px;
          min-height: 70px;
          min-width: 70px;
        }
      }

      @media only screen and (min-width: 1200px) {
        width: 70px;
        height: 70px;
        min-height: 70px;
        min-width: 70px;
      }

      svg {
        width: 30px;
        height: 30px;

        @media only screen and (min-width: 1200px) {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
    @media only screen and (min-width: 1030px) {
      .is-hoverable{
        &#hideWhenNotHovered {
          span{
            display: none;
          }
            &:hover {
              span{
                display: block;
              }
            }
          }
      }
    }

  @media only screen and (min-width: 1030px) {
    .is-hoverable {
      &#hideWhenNotHovered {
        span {
          display: none;
        }

        &:hover {
          span {
            display: block;
          }
        }
      }
    }
  }

  .navbar-dropdown {
    border-top: 5px solid $secondary;
    text-decoration: none;
    
    cursor: pointer;
    .navbar-item {
      color: black;
      text-decoration: none;
      font-weight: normal;
      font-size: 1rem;
      &:hover {
        background-color: darken($grey-lighter, 5%) !important;
      }
    }
  }
  .navbar-burger {
    color: white;
    align-self: center;
  }

  // change styles of nav items when in hamburger dropdown menu
  div.navbar-menu.is-active {
    .navbar-item {
      text-decoration: none;
      background-color: white;
    }
    .nav-subtitle {
      color: black;
      border-bottom: 1px $primary solid;
      display: block;

      &:hover {
        background-color: $grey-lighter;
      }
    }
    color: black !important;
    align-self: center;
  }

  .logout-item {
    color: $secondary !important;
  }

  .container {
    height: 100%;
  }
}
