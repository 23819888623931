@import "../../styles/variables";

.dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;

  padding: 125px 50px 35px 50px;

  .report-header {
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }

  .resources-container {
    margin-top: 20px;
  }

  @media only screen and (min-width: 1500px) {
    flex-direction: row;

    .left-side {
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  .left-side {
    flex-grow: 1;
    margin-bottom: 50px;
  }

  .right-side {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .total-tshirts-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .total-tshirts-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: $primary;
  }

  .left-side-label {
    font-weight: 500;
    margin-right: 5px;
  }
}

// mobile view
@media only screen and (max-width: 1366px) {
  .dashboard-container {
    padding: 125px 0px 25px 0px;

    .right-side {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
