@import "../../styles/variables";

.dropdown {
  // small

  .dropdown-trigger {
    padding: 0;
    border: none;
    margin: 0;
  }

  a.dropdown-item {
    text-transform: uppercase;
    font-family: "Oxygen", sans-serif;
    
  }

  a.dropdown-item.is-active {
    background-color: $secondary;
  }
}
