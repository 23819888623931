@import "../../styles/variables";

.searchbar-container {
  .control {
    display: flex;
    position: relative;
  
    .input {
      color: $primary;
      font-family: $font-secondary;
    }

    .icon.is-right {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;

      svg {
        color: $primary;
      }
    }
  }
}