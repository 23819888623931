@import "../../styles/variables";

.page-head {
  .button {
    border-radius: 20px 0 20px 20px;
  }
}
.label {
  font-size: 0.8rem;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $primary;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 25px;
}
.password-visibility {
  position: absolute;
  right: 8px;
  top: 28px;
  cursor: pointer;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #cccccc;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 25px;
}
