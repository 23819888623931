.review-cycle-row {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.bold-text {
  font-weight: 600;
}

.margin-left {
  margin-left: 1rem;
}

.admin-checkbox {
  height: 1.25rem;
  width: 1.25rem;
}

.tooltip {
  font-size: medium;
}
