@import "../../../../styles/variables";

.data-table-container {
  position: relative;

  .t-container {
    width: 100%; //table size
    margin: auto; //center table

    .searchbar-container {
      width: 200px;
      margin-left: auto;
    }
  }

  &.is-fixed-width {
    max-width: 100%;
  }

  table {
    width: 90%;
    margin: auto; //center table
    border-collapse: separate;
    border-spacing: 0 1.5rem;
    background-color: transparent;
    td {
      font-family: $font-secondary !important;
      color: $primary;
      border: none !important;
      flex: 3; // make profile image take up less room

      // .cell-img-wrapper {
      // }
      svg {
        font-weight: normal !important;
      }
    }

    td:nth-child(1) {
      text-align: left; // keep profile button closer to rows
      flex: 1;
    }
    td,
    th {
      cursor: pointer;
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          font-weight: normal;
          letter-spacing: 1px;
          font-size: 1rem;
          flex: 3;
          background-color: $white;
          color: $primary;
          padding-left: 1rem;
          border-bottom: 1px solid $primary;
          font-weight: 600;

          &:first-child {
            border-bottom: 1px solid $primary;
          }

          &:first-child {
            background-color: transparent;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: transparent;
        &:hover {
          td {
            .cell-style-wrapper {
              background-color: $white-ter;
            }
          }
        }

        td {
          vertical-align: middle;
          padding: 0;

          .cell-style-wrapper {
            background-color: $grey-light;
            padding: 15px 15px 15px 15px;
          }

          .project-cell-style-wrapper {
            background-color: $grey-light;
            padding: 8px 15px 10px 15px;
          }

          &:first-child {
            background-color: transparent;
          }

          .user-icon-wrapper {
            width: 62px;
            padding: 15px;
            border-radius: 50px;
            border: 1px solid $secondary;
            background-color: $secondary;
            margin-left: auto;
            margin-right: 15px;

            svg {
              font-size: 26px;
              color: $white;
            }
          }

          .image-icon-wrapper {
            text-align: right;
            margin-right: 35px;

            img {
              object-fit: cover;
              max-width: 150%;
              max-height: 150%;
            }
          }
        }
      }
    }

    tr {
      width: 100%;
      white-space: normal;
      transition: text-shadow 0.2s;
      img {
        border-radius: 50%;
      }
    }
  }
  pre {
    padding: 0;
    background: none;
  }

  .pagination-field {
    padding-bottom: 20px;
  }

  .sub-row {
    .sub-cell {
      // vertical-align: unset;
      // background-color: $white-ter;

      &:not(:first-child) {
        border-left: 5px solid $white !important;
      }

      &:not(:last-child) {
        border-right: 5px solid $white !important;
      }

      .generic-table-container {
        max-height: 400px;
        overflow-y: auto;

        .generic-table {
          border-collapse: collapse;

          .cell-style-wrapper {
            font-weight: normal;
            color: $primary;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 767px) {
//   .data-table-container {
//     position: relative;
//     font-size: 0.8rem;
//     &.is-fixed-width {
//       max-width: 100%;
//     }
//     table {
//       width: 100%;

//       td {
//         color: $black;
//         text-align: center !important;
//         margin: 2rem !important;
//         padding: 2rem !important;
//       }
//       td,
//       th {
//         cursor: pointer;
//         &:not(.note) {
//           white-space: pre-wrap;
//         }
//         a {
//           text-decoration: none;
//           color: $blue;
//         }
//       }
//     }
//   }
// }
.no-contractors-found-wrapper {
  display: flex;
  justify-content: center;
  border-top: 1px $primary solid;
  margin-top: 41px;
  max-width: unset;

  .contractor-notification {
    background-color: $grey-light;
    color: $primary;
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }
}
