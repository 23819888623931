@import "../../../../styles/variables";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-content {
  animation-name: fadeIn;
  animation-duration: 0.25s;
  #review-cycle-buttons {
    display: flex;
    justify-content: space-between;
    #cancel-btn {
      background-color: $secondary;
      color: white;
    }
    #danger-btn {
      background-color: $red;
      color: #fff;
    }

    #danger-btn:hover {
      background-color: $red-dark;
      color: #fff;
    }
    .button {
      min-width: 4rem;
      margin: 0 0.1rem;
    }
  }
  #review-cycle-form {
    .summary-container {
      animation-name: fadeIn;
      animation-duration: 0.3s;
    }
  }
}
@media (max-width: 700px) {
  #review-cycle-form-mobile {
   .modal-head {
    display: flex;
    flex-direction: column;
    .modal-head-start{
      display: flex;
      flex-direction: row;
    }
    .modal-head-end {
      display: flex;
      width: 100%;
      margin-top: 0.5rem;
      flex-direction: row;
      justify-content: space-between;
      height: auto;
    }
   } 
  }
}