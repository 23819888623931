@import "../../styles/variables";

#danger-btn {
  background-color: $red;
  color: #fff;
}

#danger-btn:hover {
  background-color: $red-dark;
  color: #fff;
}

.btn-width {
  width: 7rem !important;
}
