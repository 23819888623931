@import "../../styles/variables";

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.show-details-container {
  background-color: $white-ter;
  width: 100%;
  border-radius: 2px;
  height: 3rem;
  height: auto;
}

.back-btn {
  margin-left: 0.5rem;
  background-color: $white-ter;
  border-color: $white-ter;
  color: $primary;

  .arrow-icon {
    margin-right: 0.5rem;
  }
}

.progress-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;

  .not-started-icon {
    color: $red;
    margin-left: 0.5rem;
    animation-name: fadeInAndOut;
    animation-duration: 1s;
  }

  .completed-icon {
    color: $green;
    margin-left: 0.5rem;
    animation-name: fadeInAndOut;
    animation-duration: 1s;
  }
}
