@import "../../../../styles/variables";

.evaluation-date {
  margin-left: auto;
  color: $primary;
}

.align-end {
  margin-left: auto;
}

.btn-margin {
  margin-bottom: 0.5rem !important;
}

.important-text {
  color: $red !important;
}

#sign-off-container {
  margin-top: 0.5rem;
}
