@import "../../styles/variables";
.container {
  display: inline;
  width: 100%;
}

.container-goals {
  display: inline;
  width: 60%;
}

.inner-container {
  display: flex;
  justify-content: center;
  margin-top: 7.5rem;
}

.add-button-container {
  display: flex;
  justify-content: center;
}

.centerTitle {
  text-align: center;
}

.reviews-outer-container {
  display: flex;
  width: 100%;
}

.grid-one-fr {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  height: 25rem;
}

.reviews-outer-container-goals {
  display: flex;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.reviews-outer-container-manager {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.reviews-outer-container-manager-goals {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.reviews-container-section:first-child {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  margin-left: 2rem;
  margin-right: 1rem;
}
.reviews-container-section {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.reviews-container-section:last-child {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding-top: 2rem;
  margin-left: 1rem;
  margin-right: 2rem;
}

.selectedBtn {
  color: #fff;
  background-color: $secondary;
  margin: -1px;
}

.unselectedBtn {
  color: $primary;
  border-bottom: 2px solid $secondary;
  margin: -1px;
}

.title {
  color: $primary;
  font-family: "EurostileExtended", sans-serif;
}

.centered-title {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.section {
  border-bottom: 1px solid $primary;
}

.reviews {
  background-color: #f5f5f5;
  padding: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}

.review-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.review-title {
  font-weight: 600;
}

.review-option-primary-goal {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $primary;
  color: #fff;
  margin-right: auto;
}

.review-option-secondary-goal {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $secondary;
  color: #fff;
  margin-right: auto;
}

.review-option-tertiary-goal {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $red;
  color: #fff;
  margin-right: auto;
}

.review-option-sign-off-goal {
  margin-left: auto;
  min-width: 10rem;
  height: 3rem;
  text-align: center;
  background-color: $green-med;
  color: #fff;
  margin-right: auto;
}

.option-mobile {
  margin-left: 0rem;
}

.backBtn {
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 2rem;
  background-color: $primary;
  color: #fff;
}

.title-container {
  display: flex;
  align-items: center;
}

.title-end {
  margin-left: auto;
}

@media (max-width: 767px) {
  .reviews {
    background-color: transparent;
    width: 100%;
    max-height: 100svh;
    overflow-y: scroll;
    padding: 1rem;
    padding-top: 0rem;
    margin-left: -0.5rem;
    margin-bottom: 2rem;
  }
  .review-item {
    background-color: #f5f5f5;
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
  .review-option-primary {
    width: 100%;
    height: 3rem;
    text-align: center;
    background-color: $primary;
    color: #fff;
  }

  .review-option-secondary {
    height: 3rem;
    text-align: center;
    background-color: $secondary;
    color: #fff;
    width: 100%;
  }

  .review-option-tertiary {
    width: 100%;
    height: 3rem;
    text-align: center;
    background-color: $red;
    color: #fff;
  }

  .review-option-sign-off {
    width: 100%;
    height: 3rem;
    text-align: center;
    background-color: $green-med;
    color: #fff;
  }
  .reviews-outer-container-manager-goals {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .reviews-container-section:last-child {
    margin-right: 0;
  }
}
