@import "../../styles/variables";

.partner-services-container {
  height: auto;
  min-height: 100vh;
  position: relative;
  padding: 125px 50px 50px 50px;

  .filters-container {
    width: 80%;
    margin: 0 auto;
    position: relative;
    left: 25px;
  }

  .filter-btn-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .hidden {
    display: none;
  }

  .show {
    display: block;
    background-color: #f5f5f5;
    padding: 12px;
  }
  .filter-label {
    color: $primary;
  }

  .filter-btn {
    background-color: $primary;
    color: white;
    height: 40px;
    width: 60px;

    &:hover {
      background-color: lighten($color: $primary, $amount: 5%);
      color: white;
    }

    &:focus {
      color: white;
      border: none;
    }
    &:active {
      color: white;
      transform: translateY(1px);
      filter: saturate(110%);
      border: none;
    }
  }

  .export-btn {
    background-color: $danger;
    border-radius: 3px;
    color: white;
    height: 39px;
    width: 60px;

    &:hover {
      background-color: lighten($color: $danger, $amount: 5%);
      color: white;
    }

    &:focus {
      color: white;
      border: none;
    }
    &:active {
      color: white;
      transform: translateY(1px);
      filter: saturate(110%);
      border: none;
    }
  }

  .cell-style-wrapper {
    height: 50px;
    white-space: nowrap;
  }

  .table-container {
    h3 {
      margin-bottom: 0;
      color: $primary;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      padding: 10px;
      background-color: $white;
    }

    .generic-table-container {
      .t-container {
        width: 100%;
        margin: unset;
      }
    }
  }
}
