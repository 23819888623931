@import "../../styles/variables";

.table-section {
  box-shadow: 1px 2px rgba(0, 0, 0, 0.09);
  background-color: white;
  padding: 1.5rem;
  border: 2px solid black;
  border-radius: 20px 20px 20px 20px;
  .data-table-container {
    position: relative;
    font-size: 0.8rem;
    margin-bottom: 1.5rem;

    &.is-fixed-width {
      max-width: 100%;
    }
    table {
      width: 100%;
      font-family: "Roboto Mono", monospace;
      td,
      th {
        cursor: pointer;
        &:not(.note) {
          white-space: nowrap;
        }
      }
      thead {
        border-bottom: solid 4px $primary;
        tr {
          td:first-child,
          th:first-child {
            width: 2rem;
          }
          th {
            font-weight: normal;
            letter-spacing: 1px;
            border: none;
            background: transparent;
            color: $black;
            padding: 0.25em 1em;
          }
          .right {
            text-align: right;
          }
          .left {
            text-align: left;
          }
        }
      }
      tbody {
        color: $grey;
        tr {
          td {
            border: none;
            border-bottom: 2px solid $background;
            padding: 1em !important;
          }
          .right {
            text-align: right;
          }
          .left {
            text-align: left;
          }
        }
      }
    }
    pre {
      padding: 0;
      background: none;
    }
  }
}
// .data-table-pagination {
//   z-index: 1;
//   position: fixed;
//   bottom: 2rem;
//   right: 0;
//   margin: 0 2rem;
//   .box {
//     background-color: hsl(0, 0%, 100%);
//   }
//   // transform: translate3d(-100%, 0, 0);
// }
