@import "../../styles/variables";

.workshop-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding: 150px 50px 50px 50px;

  .title {
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
  }

  .section {
    border-bottom: 1px solid $primary;
  }

  .workshop{
    width: 100%;
    background-color: $grey-lightest;
    table {
      width: 100%;
      font-family: "Roboto Mono", monospace;
      box-shadow: 1px 2px rgba(0, 0, 0, 0.09);
      td,
      th {
        cursor: pointer;
       
        &:not(.note) {
          white-space: nowrap;
        }
      }
      
      thead {
        border-bottom: solid 4px black;
            background-color: $white;
        tr {
         
          border-bottom: solid 4px black;
          td:first-child,
          th:first-child {
            width: 2rem;
           
          }
          th {
            font-weight: normal;
            letter-spacing: 1px;
            border: none;
            background: transparent;
            color: $black;
            padding: 0.25em 1em;
            &:hover {
                background-color: $grey-lightest;
              }
          }
          .right {
            text-align: right;
          }
          .left {
            text-align: left;
          }
        }
      }
      tbody {
        color: $black;
          tr {
            background: $white;
          }
          tr:hover {
            background-color: #88acef78;
          }
        
          tr:nth-of-type(even) {
            background: $grey-lightest;
            &:hover{
              background-color: #88acef78;
            }
          }
        tr {
          td {
            border: none;
            border-bottom: 2px solid $background;
            padding: 1em !important;
          }
          .right {
            text-align: right;
          }
        }
      }
    }
  }
}
