@import "../../styles/variables";

.signup-container {
    height: 100vh;
    background-color: $primary;

    .signup-wrapper {
        width: 350px;
        position: relative;
        top: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;

        .logo {
            text-align: center;
            margin-bottom: 60px;

            img {
                width: 200px;
                height: 178px;

                @media only screen and (min-width: 768px) {
                    width: 248px;
                    height: 226px;
                }
            }
        }

        .form-box {
            text-align: center;

            input {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid white;
                border-radius: unset;
                color: white;
                padding-left: 5px;
                font-family: "Oxygen", sans-serif;
                box-shadow: none;

                &:first-child {
                    margin-bottom: 20px;
                }

                &:nth-child(2) {
                    margin-bottom: 20px;
                }

                &:nth-child(3) {
                    margin-bottom: 10px;
                }

                &:active {
                    box-shadow: none;
                }
            }

            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px $primary inset;
                box-shadow: 0 0 0 1000px $primary inset;
                -webkit-text-fill-color: white;
                caret-color: white;
            }

            a {
                color: $secondary;
                font-family: "Oxygen", sans-serif;
                font-size: 0.75rem;
            }

            button {
                margin-top: 40px;
                text-transform: uppercase;
                background-color: transparent;
                font-family: "Oxygen", sans-serif;
                color: white;
                border-radius: unset;
                border-width: 4px;
                font-size: 0.75rem;
                width: 175px;
                height: 40px;

                &:hover {
                    background-color: $secondary;
                    border-color: $secondary;
                }
            }
        }
    }
}