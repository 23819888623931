@import "../../../../styles/variables";

.summary-item {
  width: 100%;
  border: 1px solid $primary;
  border-radius: 2px;
  margin-bottom: 1rem;
  padding: 1rem;

  .summary-item-title {
    display: flex;
    align-items: center;

    .not-started-icon {
      color: $red;
      margin-right: 0.5rem;
      margin-left: -0.25rem;
    }

    .completed-icon {
      color: $green;
      margin-right: 0.5rem;
    }

    .bold-text {
      font-weight: 600;
    }
  }

  .secondary-btn {
    color: #fff;
    background-color: $secondary;
    border-radius: 2px;
    margin-left: 0.5rem;
    width: 6rem;
    height: 2rem;
  }

  .summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
