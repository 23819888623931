@import "../../styles/";

.report-container {
  background-color: $white-bis;
  padding: 0px 20px 10px 20px;

  position: relative;

  .report-wrapper {
    overflow-x: auto;
    margin-bottom: 10px;
    max-height: 38vh;
    overflow: auto;
  }

  .table-utils {
    display: flex;
    position: absolute;
    right: 5px;

    .download-btn {
      background-color: transparent;
      color: $secondary;
      border-color: unset;
      border-width: 0;
      &.is-active,
      &:focus-within {
        border: $secondary 1px solid;
        box-shadow: none !important;
      }
    }
    .add-btn {
      background-color: $primary;
      color: $white;
      border-color: unset;
      border-width: 0;
      :focus, :active {
          color: $white
        }
}

    .dropdown {
      margin-right: 10px;
      display: flex;
      align-items: center;

      &.is-active,
      &:focus-within {
        button {
          border: $secondary 1px solid;
          box-shadow: none !important;
        }
      }

      span {
        background-color: transparent;
        color: $secondary !important;
        font-family: "Oxygen", sans-serif;
        font-weight: 600;
        &.is-active,
        &:focus-within {
          .button {
            border: $secondary 1px solid;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .title {
    text-align: center;
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
    text-transform: uppercase;
    padding-top: 20px;
  }

  .report-table {
    background-color: transparent;
    tbody {
      tr {
        &:last-child {
          td {
            border-bottom-width: 2px;
          }
        }
      }
    }

    th {
      border-width: 0 0 2px 0;
      border-color: $secondary;
      white-space: nowrap;
    }
    tr {
      th {
        font-family: "Oxygen", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: $primary;
        &:not([align]) {
          text-align: center;
        }
      }

      td {
        text-align: center;
        border: 2px solid $primary;
        color: $font-grey;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 0.75rem;
        vertical-align: middle;

        &:first-child {
          border-width: 2px 2px 2px 0;
          font-weight: 600;
        }

        &:last-child {
          border-width: 2px 0 2px 2px;
        }
      }

      &:last-child {
        td {
          border-bottom-width: 2px;
        }
      }
    }
  }

  .button {
    background: transparent;
    border: none;
    :active,
    :focus {
      color: $secondary;
    }
  }
}
