@import "variables";

@import "bulma";
@import "~rbx/rbx";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
@import "@creativebulma/bulma-tooltip";

@font-face {
  font-family: "EurostileExtended";
  src: url(../fonts/eurostile_extended_black.ttf);
}

.ph-w::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.ph-primary::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primary;
  opacity: 1; /* Firefox */
}
.ph-grey::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey;
  opacity: 1; /* Firefox */
}

*,
*:before,
*:after {
  font-family: "Avenir", "Roboto", sans-serif;
}

.main-container {
  position: absolute;
  left: 0;
  right: 0;
}

.page-head {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 0.75rem;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
  .page-head-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    :not(:last-child) {
      margin-right: 0.5rem;
    }
    .buttons {
      button {
        margin-bottom: 0;
      }
    }
  }
  .page-head-start {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
  .page-head-start--subheader {
    > * {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
  .title,
  .breadcrumb {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.modal {
  &.is-active {
    z-index: 999;
  }
  .modal-content {
    width: 90%;
    max-width: 900px;
    .baseball-form-container-mobile {
        display: flex;
        flex-direction: column;
      }
  }
}

.button.is-primary {
  text-align: center;
  margin: 0 5px;
  background: linear-gradient(to right, lighten($primary, 5%), $primary);
  &:hover {
    background: linear-gradient(
      to right,
      lighten($primary, 10%),
      lighten($primary, 5%)
    );
  }
}

.button.is-secondary {
  color: $white;
  background: linear-gradient(to right, lighten($purple, 5%), $purple);
  &:hover {
    color: $white;
    background: linear-gradient(
      to right,
      lighten($purple, 8%),
      lighten($purple, 5%)
    );
  }
}

.button.is-secondary {
  background: $primary;
  color: $white;
  margin: 0 5px;
  background: linear-gradient(10deg, $secondary 30%, $yellow 85%);
  &:hover {
    background: linear-gradient(
      to right,
      lighten($secondary, 10%),
      lighten($primary, 5%)
    );
  }
}

// .box {
//   box-shadow: 1px 2px rgba(0, 0, 0, 0.09);
//   border-radius: 0px 20px 20px 20px;
//   margin-bottom: 1rem !important;
//   .button {
//     border-radius: 20px 0px 20px 20px;
//   }
// }
.box {
  .buttons {
    button {
      text-transform: uppercase;
      border-radius: 5px;
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 5px !important;

      &:first-child {
        background-color: $secondary;
        color: $white;
      }
    }
  }
  .button.is-danger {
    text-transform: uppercase;
    background-color: #ff585d;
    border-radius: 5px;
  }
}

.is-small.input,
.select.is-small {
  border-radius: 5px;
}

hr {
  background-color: $primary;
}

select[disabled] {
  background-color: hsl(0, 0%, 95%) !important;
  border: solid 1px hsl(0, 0%, 90%) !important;
  border-radius: 4px !important;
}

.input[readonly] {
  background: transparent;
}
input:read-only {
  border: none;
}
.section-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.modal-head {
  @media (max-width: 700px){
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .modal-head-start,
  .modal-head-end {
    display: flex;
    align-items: center;
  }
  .title {
    vertical-align: middle;
  }
  .buttons {
    .button {
      margin-bottom: 0;
    }
  }
}

.modal-head-details {
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 0.5rem;
  margin-top: 0.5rem;
}

.highlighted-details-text {
  color: $primary;
}

.modal-section {
  @media print {
    overflow: visible !important;
  }
  height: 40rem;
  overflow-y: auto;
}

.button {
  @media print {
    display: none !important;
  }
}

.field {
  &.grid-override {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.75rem;

    &.is-mobile {
      grid-template-columns: 1fr;
      .control.is-expanded {
        margin: 0;
        width: 70%;
      }
    }
    &.is-mobile-projects {
      grid-template-columns: 1fr;
      .control.is-expanded {
        margin: 0;
        width: 100%;
      }
    }
    &.is-three {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.is-four {
      grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    }
    &.is-five {
      grid-template-columns: repeat(5, 1fr);
    }
    &.is-four-complete {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .control.is-expanded {
      margin: 0;
    }
  }
  &.grid-override-mobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.75rem;
  }
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

input:required,
select:required,
textarea:required {
  &:invalid {
    border: 1px #e63362 solid;
    box-shadow: 0 0 0 0.125em rgba(230, 51, 98, 0.25);
  }
}

@media (max-width: 700px) {
  .modal-head-details {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    margin-top: 0.5rem;
  }
}
