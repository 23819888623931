@import "../../styles/variables";

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.logo-wrapper {
  position: relative;
  height: 150px;
  width: 100px;
}

.logo-text {
  color: $primary;
  font-size: 18px;
  font-weight: bold;
  font-family: "EurostileExtended", sans-serif;
  position: relative;
  top: 54px;
  left: -4px;
}
.lds-spinner {
  color: $primary;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.7s linear infinite;
}
.lds-spinner div:after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 37px;
  width: 6px;
  height: 15px;
  border-radius: 20%;
  background: $primary;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.7s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(20deg);
  animation-delay: -1.6s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(40deg);
  animation-delay: -1.5s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(60deg);
  animation-delay: -1.4s;
}
// .lds-spinner div:nth-child(5) {
//   transform: rotate(80deg);
//   animation-delay: -1.3s;
// }
// .lds-spinner div:nth-child(6) {
//   transform: rotate(100deg);
//   animation-delay: -1.2s;
// }
.lds-spinner div:nth-child(7) {
  transform: rotate(120deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(140deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(160deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(180deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(200deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(13) {
  transform: rotate(220deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(14) {
  transform: rotate(240deg);
  animation-delay: -0.5s;
}
// .lds-spinner div:nth-child(15) {
//   // transform: rotate(260deg);
//   animation-delay: -0.4s;
// }
// .lds-spinner div:nth-child(16) {
//   // transform: rotate(280deg);
//   animation-delay: -0.3s;
// }
.lds-spinner div:nth-child(17) {
  transform: rotate(300deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(18) {
  transform: rotate(320deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(19) {
  transform: rotate(340deg);
  animation-delay: -0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
