.flex-list-item {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
}

.text-input {
  padding: 0.5rem;
}

.dropdown-container {
  margin-left: auto;
}

.dropdown {
  width: 14rem;
}

@media (max-width: 700px) {
  .flex-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }
}