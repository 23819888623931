@import "../../styles/variables";

.modal-ce-form {
  h3 {
    font-family: "EurostileExtended", sans-serif;
    font-size: 1.438rem;
    color: $primary;
    margin-bottom: 20px;
  }

  .calendar-events-form-box {
    flex-direction: column;
    margin-bottom: 0;

    .buttons-wrapper {
      div {
        &:last-child {
          margin-left: auto;
          display: flex;
          gap: 20px;
        }
      }
    }
  }
}