@import "../../styles/variables";

.settings-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  padding: 150px 50px 50px 50px;

  .title {
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
  }

  .section, .section-mobile {
    border-bottom: 1px solid $primary;
    @media (max-width: 767px) {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      .MuiBox-root {
          max-width: 70svw;
        }
      .section-header {
        display: flex;
        margin-top: 1rem;
        flex-direction: column;
       
      }
    }
  }
}
