@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.summary-container {
  max-height: 21.5rem;
  overflow-y: auto;
  animation-name: fadeIn;
  animation-duration: 1s;
}
