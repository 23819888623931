@import "../../styles/variables";

.assignment-history-table{
  th {
    color: $primary;
    font-family: "Oxygen", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    background-color: white;
  }
}

.baseball-card-container2 {
  padding: 100px 20px 40px 20px;
  z-index: 1;
  background-color: $white;

  .print-button {
    background-color: transparent;
    color: $secondary;
    border: none;
    border-width: 0;
    padding-top: 0;
  }
  
  .bball-card-avatar-container {
    width: 140%;

    .bball-card-image-box {
      background-color: $light-blue;
      box-shadow: 10px 10px $green-med;
      height: 100%;
      margin: 4rem 0 0 2rem;

      svg {
        width: 50%;
        height: auto;
        color: $white;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .bball-header-container {
    background-color: $primary;
    color: white;
    .bball-header-title {
      span {
        font-family: "EurostileExtended", sans-serif;
      }
    }
    .bball-header-title-mobile{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .c-secondary {
      color: $secondary;
    }
    .c-white {
      color: $white;
    }
    &:after {
      content: "";
      height: 35px;
      width: 100%;
      background: repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4) 15px,
        $primary 15px,
        $primary 40px
      );
    }
  }

  .bball-card-trainings-container {
    background-color: $secondary;
    color: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div.table-info > div.cell-wrapper .styledTable {
      .table-container {
        .table {
          th {
            color: $white;
            text-align: center;
            border: none;
          }
          tr {
            border: none;
          }
          td {
            border: none;
          }

          background-color: transparent;
          color: $white;
          width: 100%;
        }
      }
    }
    .header-wrapper {
      display: flex;

      .print-button {
        margin-top: 7px;
        color: $primary;
      }
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      font-family: "EurostileExtended", sans-serif;
      color: $white;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    .table-info {
      margin-top: 10px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &:first-child {
          align-items: flex-start;

          max-height: 365px;
          overflow-y: auto;
        }
        & > span {
          color: $white;
          font-family: "Oxygen", sans-serif;
          font-weight: 600;
        }
      }

      .print-button {
        color: $primary;
      }

      .cell-wrapper {
        div {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          color: $white;
          width: 100%;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        .tech-courses-input-wrapper {
          display: flex;
          .input {
            flex-grow: 1;
          }

          .control.is-expanded {
            input {
              flex: 1;
            }
          }
        }
      }
    }
  }

  .bball-card-table-container {
    background: $grey-light;
    .center-section {
      display: flex;
      .center-table {
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;

        .center-table-wrapper {
          overflow-y: auto;
          height: 100%;
          background: $white-bis;
          padding-top: 10px;
          padding-bottom: 10px;

          h3 {
            text-align: center;
            text-transform: uppercase;
            font-family: "EurostileExtended", sans-serif;
            color: $primary;
            font-size: 1.5rem;
            margin-bottom: 25px;
          }

          .tabs ul {
            max-width: 75%;
            color: $primary;
            font-family: "Oxygen", sans-serif;
            font-size: 0.875rem;
            text-transform: uppercase;
            font-weight: 600;
            margin: auto;
          }

          .yos-note-input {
            width: 90%;
            margin: auto;
            padding-bottom: 10px;
          }

          .table-info {
            width: 100%;
            max-width: 100vw;
            padding-left: 1rem;
            padding-right: 1rem;

            .qualifications-wrapper {
              padding-left: 15px;
              padding-right: 15px;

              .qualifications {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: $primary;
                font-family: "Oxygen", sans-serif;
                font-weight: 600;
                margin-bottom: 10px;
                gap: 10px;
              }

              .textarea {
                flex: 1;
                min-height: fit-content !important;
                padding: 0;
                height: 100%;
                border: none;
                font-weight: normal !important;
                box-shadow: none !important;
                background-color: transparent;
                resize: none !important;
                &::-webkit-scrollbar {
                  display: none;
                }
                &.edit-mode {
                  background-color: white;
                }
              }

              .date {
                margin-right: 5rem;
              }
            }

            & > div {
              display: flex;
              flex-direction: column;
              &:first-child {
                padding-right: 20px;
              }
              & > div {
                color: $primary;
                font-family: "Oxygen", sans-serif;
                font-weight: 600;
              }
            }

            .ti-01 {
              flex-direction: row;
              min-width: 10%;
              width: 33%;
            }

            input {
              &:not(:last-child) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      // decco u courses table
      .styledTable {
        .title {
          text-align: center;
          color: $white;
          font-family: "EurostileExtended", sans-serif;
          text-transform: uppercase;

          .styledTable .generic-table tr th {
            color: white !important;
          }
        }
      }
    }
  }

  .bball-card-buttons-container {
    padding: 2rem 0 1rem 2rem;
    overflow-wrap: normal;
    .c-secondary {
      color: $secondary;
    }
    .user-title {
      width: 100%;
    }
    
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 0.5rem;
      padding-top: 2rem;
      @media only screen and (max-width: 899px) {
          padding-left: 0;
        }
      .button:not(:last-child):not(.is-fullwidth) {
        margin-right: 0;
      }

      button,
      span.file-label {
        min-width: 20px;
        text-transform: uppercase;
        padding: 8px;
        border-radius: 6px !important;
        background-color: $white;
        color: $primary;
        border: 4px solid $primary;
        font-family: "Oxygen", sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
        justify-content: center;
        margin: 0;
        width: 100%;
      }

      button {
        padding: 20px !important;
        background-color: $primary;
        color: white;
        border: unset;
        min-width: 200px;
      }

      span.file-label[disabled] {
        border-color: #dbdbdb;
        box-shadow: none;
        opacity: 0.5;
      }

      button.cancel-button {
        background-color: $secondary;
        color: white;
      }
      .edit-button {
        background: $secondary;
      }

      .light-blue-btn {
        background: $green-med;
      }

      .file-cta {
        height: 50px;
        background-color: transparent;
        padding: 0 !important;
      }
    }
  }

  @media only screen and (max-width: 899px) {
    .bball-card-avatar-container {
      width: 100%;

      .bball-card-image-box {
        box-shadow: 5px 5px $green-med;
        margin: 0 !important;
      }
    }
  }

  @media only screen and (max-width: 899px) {
    .bball-card-buttons-container {
      padding: 0;
    }
  }
}
