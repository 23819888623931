@import "../../styles/variables";

.data-table-pagination {
  display: flex;
  justify-content: center;

  button.prev, button.next {
    background-color: $primary;
    color: $white;
  }
}