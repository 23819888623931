@import "../../styles/variables";

.lic-table-container {
  background-color: $white-bis;
  padding: 20px 10px 0 10px;

  .lic-table-wrapper {
    overflow-x: auto;
    margin-bottom: 60px;
  }

  .title {
    text-align: center;
    color: $primary;
    font-family: "EurostileExtended", sans-serif;
    text-transform: uppercase;
  }

  .exp-lic-table {
    background-color: transparent;
    tbody {
      tr {
        &:last-child {
          td {
            border-bottom-width: 2px;
          }
        }
      }
    }
  
    th {
      border-width: 0 0 2px 0;
      border-color: $secondary;
    }
    tr {
      th {
        font-family: "Oxygen", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: $primary;
        &:not([align]) {
          text-align: center;
        }
      }
  
      td {
        text-align: center;
        border: 2px solid $primary;
        color: $font-grey;
        font-weight: 600;
        font-size: 0.875rem;
  
        &:first-child {
          border-width: 2px 2px 2px 0;
          color: $primary;
          font-weight: 600;
        }
  
        &:last-child {
          border-width: 2px 0 2px 2px;
        }
      }
  
      &:last-child {
        td {
          border-bottom-width: 2px;
        }
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;

    button {
      text-transform: uppercase;
      color: $secondary;
      background-color: transparent;
      font-weight: 600;
      font-family: "Oxygen", sans-serif;
      border: unset;
    }
  }
}