.mass-updates-container {
  .buttons {
    margin-top: 10px;

    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}