@import "../../styles/variables";

.emp-assignments-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: 125px 50px 100px 50px;

  .filter-label {
    color: $primary;
  }

  .checkbox-label {
    color: $primary;
  }

  .table-container {
    max-height: 70vh;
    overflow-y: auto;
    h3 {
      margin-bottom: 0;
      color: $primary;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      padding: 10px;
      background-color: $white;
    }

    .generic-table-container {
      .t-container {
        width: 90%;
        margin: auto; //center table
        margin: unset;
      }
    }
  }
}
