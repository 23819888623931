@import "../../styles/variables.scss";
.Filters-container {
  height: 100%;
  padding: 10px;
  background-color: $grey-lightest;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .hide {
    display: none;
  }

  .filter-btn-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    background-color: transparent;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;

    .filter-btn {
      background-color: $primary;
      color: $grey-lightest;
      height: 40px;
      width: 60px;
      border-radius: 4px;

      &:hover {
        background-color: lighten($color: $primary, $amount: 5%);
        color: white;
      }

      &:focus {
        color: white;
        border: none;
      }
      &:active {
        color: white;
        transform: translateY(1px);
        filter: saturate(110%);
        border: none;
      }
    }

    .export-btn {
      background-color: $danger;
      color: white;
      height: 40px;
      width: 60px;
      border-radius: 4px;

      &:hover {
        background-color: lighten($color: $danger, $amount: 5%);
        color: white;
      }

      &:focus {
        color: white;
        border: none;
      }
      &:active {
        color: white;
        transform: translateY(1px);
        filter: saturate(110%);
        border: none;
      }
    }
  }
}
