@import "../../styles/variables";

.nav-dropdown {
  position: relative;
  display: inline-block;
}

.nav-dropdown-content {
  display: none;
  position: absolute;
  right: -50px;
  top: 53px;
  background-color: $white-bis;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 0;
  @media only screen and (min-width: 1200px) {
    top: 73px;
    right: -90px;
  }
}

.nav-dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.nav-dropdown-content div:first-child {
  border-top: 5px solid $secondary;
}

.nav-dropdown-content div:last-child {
  color: $secondary;
}

.nav-dropdown-content div:hover {
  background-color: $grey-lighter
}

.show {
  display: block;
}