@import "../../../../styles/variables";

.print-baseball-card-container {
  width: 99vw;
  padding: 20px;
  background-color: $white;
  margin: auto;
  height: auto;
  overflow: auto;

  Label {
    text-align: center;

    font-family: "EurostileExtended", sans-serif;
    font-size: 1.25rem;
    margin-top: 20px;
  }

  .generic-table-wrapper {
    border-top: 1px black solid;
    padding-top: 10px;
    background-color: transparent;

    margin: auto;
    border-bottom: 5px;

    .generic-table {
      width: 95%;
      margin: auto;
    }
  }

  .baseball-card-wrapper {
    .image-box {
      height: 200px;
      width: 200px;

      svg {
        width: 50%;
        height: auto;

        color: $white;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .top-section {
      clip-path: unset;
      padding-top: 10px;
      padding-bottom: 10px;

      width: 100%;

      display: flex;

      gap: 40px;
      align-items: center;
      justify-content: center;
      padding-left: 20px;

      .user-name {
        margin-bottom: 0;

        span {
          font-family: "EurostileExtended", sans-serif;
          font-size: 1.25rem;
          text-transform: uppercase;
        }
      }

      .user-info {
        font-family: "Oxygen", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .user-yos {
          margin: 0;
          span {
            font-family: "Oxygen", sans-serif;
            font-size: 1 rem;

            font-weight: 600;
            &:first-child {
              margin-right: 10px;
            }
          }
        }

        .user-other {
          display: flex;
          & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 20px;
            &:first-child {
              align-items: flex-start;
            }
            & > span {
              font-family: "Oxygen", sans-serif;
            }
          }
        }
      }
    }

    .baseball-card-container {
      padding: 60px 80px 80px 60px;
      background-color: transparent;

      .large-screen-title {
        display: block !important;

        margin-top: 200px;
      }

      .user-name {
        margin-left: 80px;
        margin-top: 5px;
        margin-bottom: 0;

        span {
          font-family: "EurostileExtended", sans-serif;
          font-size: 1.75rem !important;

          text-transform: uppercase;
        }
      }

      .bg-mask {
        display: block;
      }

      .baseball-card-wrapper {
        margin-top: 50px;
        max-width: unset;
        margin-left: unset;
        margin-right: unset;

        .print-button {
          display: flex;
        }

        .image-box {
          height: 365px;
          width: 365px;
          top: 30px;
          left: 15px;

          svg {
            width: 125px;
            height: 160px;

            margin-top: 100px;
            margin-left: 120px;
          }
          img {
            width: 365px;
            height: 365px;
            object-fit: cover;
          }
        }

        .user-title {
          display: flex;
        }

        .center-section {
          flex-direction: row;
          width: calc(100% - 400px);
          left: 400px;
          top: 150px;
          margin-top: 30px;

          .center-table {
            margin-top: 25px;
            .center-table-wrapper {
              h3 {
                font-size: 1.5rem;
              }

              .table-info {
                .cell-wrapper {
                  div {
                    flex-direction: row;
                    textarea {
                      min-width: 930px;
                    }
                    .control.is-expanded {
                      input {
                        max-width: 160px;
                        padding-top: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  .navbar {
    display: none;
  }
}
