@import "../../styles/variables";

.confirmation-container {
  height: 100vh;
  width: 100%;
  background-color: $primary;

  color: $white-ter;

  .confirmation-wrapper {
    width: 350px;
    position: relative;
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    text-align: center;

    h1 {
      font-size: 2rem;
    }

    .logo {
      text-align: center;
      margin-bottom: 60px;
      img {
        width: 200px;
        height: 178px;
        @media only screen and (min-width: 768px) {
          width: 248px;
          height: 226px;
        }
      }
    }
  }
}
