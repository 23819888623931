.list-container {
  height: 100%;
  max-height: 45rem;
  overflow-y: auto;
}

.list-container-mobile {
  height: 100%;
  max-height: 30rem;
  overflow-y: auto;
}

.fullWidth {
  width: "100%";
}

.searchBar {
  padding: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;

  .fullWidth {
    width: 100%;
    max-width: 600px;

    input {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;

      &::placeholder {
        color: #666;
      }
    }
  }
}