@import "../../../../styles/variables";

.ce-table-container {
  background-color: $white-bis;
  padding: 20px 10px 0 10px;

  .ce-table-wrapper {
    overflow-x: auto;
    margin-bottom: 60px;
    padding-bottom: 20px;
  }

  .ce-table {
    background-color: transparent;
    tbody {
      tr {
        &:last-child {
          td {
            border-bottom-width: 2px;
          }
        }
      }
    }

    th {
      border-width: 0 0 2px 0;
      border-color: $secondary;
    }

    tr {
      th {
        font-family: "Oxygen", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: $primary;

        &:not([align]) {
          text-align: center;
        }
      }
  
      td {
        text-align: center;
        border: 2px solid $primary;
        color: $font-grey;
        font-weight: 600;
        font-size: 0.875rem;

        &:first-child {
          border-width: 2px 2px 2px 0;
          color: $primary;
          font-weight: 600;
        }

        &:last-child {
          border-width: 2px 0 2px 2px;
        }
      }

      &:last-child {
        td {
          border-bottom-width: 2px;
        }
      }
    }

    &.is-hoverable {
      tbody {
        tr {
          &:not(.is-selected) {
            &:hover {
              background-color: $white-ter;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}